// customize some Bootstrap variables
$primary: darken(#428bca, 20%);

// the ~ allows you to reference things in node_modules
@import "~admin-lte/build/scss/adminlte.scss";
@import "~admin-lte/build/scss/_adminlte.raw.scss";
@import "~admin-lte/build/scss/parts/adminlte.core.scss";
@import "~admin-lte/build/scss/parts/adminlte.pages.scss";
@import "~admin-lte/build/scss/parts/adminlte.components.scss";
@import "~admin-lte/build/scss/parts/adminlte.extra-components.scss";

@import "~chart.js/dist/Chart.css"